import React from 'react'
import splashImg from '../../assets/img/splash.png'
import giftIcon from '../../assets/giftIcon.svg'

export const SplashScreen = () => {
  return (
    <div className={`flex h-screen flex-col items-center pt-[10%]`}>
      <img
        loading={'eager'}
        src={giftIcon}
        alt="giy"
        className={`h-20 w-20 self-center rounded-full`}
      />
      <img
        loading={'eager'}
        src={splashImg}
        alt="splash image"
        className={`self-center rounded-full`}
      />
    </div>
  )
}
