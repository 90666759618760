import React from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import SplashScreen from './components/SplashScreen'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import './index.scss'
import { Outlet, BrowserRouter as Router } from 'react-router-dom'

const locale = 'en'
const messages = {}

const env = sessionStorage.getItem('env-ask-betty')

let clientKey = import.meta.env.VITE_REACT_API_APPSYNC_KEY_DEV

if (env === 'test') {
  clientKey = import.meta.env.VITE_REACT_API_APPSYNC_KEY_TEST
} else if (env === 'prod') {
  clientKey = import.meta.env.VITE_REACT_API_APPSYNC_KEY_PROD
} else if (env === 'pprod') {
  clientKey = import.meta.env.VITE_REACT_API_APPSYNC_KEY_PPROD
} else {
  clientKey = import.meta.env.VITE_REACT_API_APPSYNC_KEY_DEV
}

console.log('VITE_REACT_', env)

const uriV = () => {
  console.log('VITE_REACT_', env)
  if (env === 'test') {
    return `https://api.test.ask-betty.com/graphql`
  } else if (env === 'prod') {
    return `https://api.ask-betty.com/graphql`
  } else if (env === 'pprod') {
    return `https://api.pprod.ask-betty.com/graphql`
  } else {
    return `https://api.dev.ask-betty.com/graphql`
  }
}
const client = new ApolloClient({
  uri: uriV,
  cache: new InMemoryCache(),
  headers: {
    'x-api-key': clientKey || '',
    'Access-Control-Allow-Origin': 'no-cors',
  },
})

const App = React.lazy(() => import('./App'))
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Router>
      <IntlProvider locale={locale} messages={messages}>
        <ApolloProvider client={client}>
          <Outlet />
          <React.Suspense fallback={<SplashScreen />}>
            <App />
          </React.Suspense>
        </ApolloProvider>
      </IntlProvider>
    </Router>
  </React.StrictMode>
)
